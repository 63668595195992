.app {
    font-family: sans-serif;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    text-align: center;
  }
  .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
  }
  .checked-item,
  .not-checked-item {
    margin-left: 10px;
  }
  .checked-item {
    text-decoration: line-through;
  }
/*
  .content {
    width: auto;
    height: auto;
    margin-top: 10px;
    border: 1px solid #686868;
    display: none;
    justify-content: center;
    border-radius: 10px;
}

.show {
    display: flex;
} */