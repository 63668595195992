.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: "../../images/Background.jpeg";
 }

 .resultSet-rounded-corners{
    align-items: center;
    border: 1px solid rgb(110, 96, 96);
    padding: 1%;
    border-radius: 15px;
    height: 40;
    width: 25;
  }

  .resultSet-rounded-corners1{
   border: 1px solid lightgray;
   padding: 1%;
   border-radius: 3px;
   height: 15;
   width: 5;
 }